import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import App from './App.jsx'
import './index.css'
import MainLayout from './pages/Layout/MainLayout.jsx';
import Sidebar from './navbar/Sidebar.jsx';

ReactDOM.createRoot(document.getElementById('root')).render(

    <React.StrictMode>
      <BrowserRouter>
      <MainLayout>
        {/* <Sidebar/> */}
        <App />
      </MainLayout>
      </BrowserRouter>
    </React.StrictMode>
 
)
