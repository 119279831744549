import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useAuthStore } from "@/stores/useAuthStore";
import axiosInstance from "@/utils/axiosInstance";

const ScoreRow = ({ index, score }) => (
  <TableRow key={index}>
    <TableCell>{index + 1}</TableCell>
    <TableCell>{score.name}</TableCell>
    <TableCell>{score.club}</TableCell>
    <TableCell>{score.totalScore}</TableCell>
  </TableRow>
);

const ScoresTable = ({ scores, loading }) => (
  <div className="border w-full sm:w-[75vw] border-lg rounded-lg">
    {loading ? (
      <p>Loading...</p>
    ) : (
      <Table className="font-semibold">
        <TableHeader className="font-bold">
          <TableRow className="w-full">
            <TableHead className="w-1/12">No</TableHead>
            <TableHead className="w-1/4">Nama</TableHead>
            <TableHead className="w-1/4">Club</TableHead>
            <TableHead className="w-1/4">Total Poin</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {scores.map((score, index) => (
            <ScoreRow key={index} index={index} score={score} />
          ))}
        </TableBody>
      </Table>
    )}
  </div>
);

export default function Monitor() {
  const [maleScores, setMaleScores] = useState([]);
  const [femaleScores, setFemaleScores] = useState([]);
  const [loading, setLoading] = useState(true);

  const { accessToken } = useAuthStore();

  const fetchLiveScores = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/scores/live-score", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = response.data.data;
      setMaleScores(data.male);
      setFemaleScores(data.female);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching live scores:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLiveScores();
    const intervalId = setInterval(fetchLiveScores, 300000); // Fetch scores every 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [accessToken]);

  return (
    <div className="flex flex-col justify-center items-center mt-9 w-screen">
      <img src="/logo2.png" width="200" alt="Logo" className="m-0 p-0" />
      <h2 className="w-full text-center sm:w-[75vw] font-bold">Papan Peringkat Jemparingan</h2>
      <div className="w-full sm:w-[75vw] mt-3 mb-3 font-medium">
        <h3 className="w-[150px] text-center rounded-sm text-white sm:w-[150px] bg-[#047601]">
          Kategori Putra
        </h3>
      </div>
      <ScoresTable scores={maleScores} loading={loading} />
      <div className="w-full sm:w-[75vw] mt-3 mb-3 font-medium">
        <h3 className="w-[150px] text-center rounded-sm text-white sm:w-[150px] bg-[#047601]">
          Kategori Putri
        </h3>
      </div>
      <ScoresTable scores={femaleScores} loading={loading} />
    </div>
  );
}
