import { create } from 'zustand';
import { login } from "@/services/auth/login";
import { refresh } from "@/services/auth/refresh";
import { persist } from "zustand/middleware";

export const useAuthStore = create(
  persist(
    (set, get) => ({
      accessToken: null,
      refreshToken: null,
      authenticatedEmail: null,

      doLogin: async ({ email, password }) => {
        try {
          const data = await login({ email, password });

          if (data && data.tokens && data.tokens.access_token && data.tokens.refresh_token) {
            set({
              accessToken: data.tokens.access_token,
              refreshToken: data.tokens.refresh_token,
              authenticatedEmail: email, // Save authenticated email
            });
          }
          return data;
        } catch (error) {
          throw error;
        }
      },

      doLogout: () => {
        set({
          accessToken: null,
          refreshToken: null,
          authenticatedEmail: null, // Clear authenticated email
        });
      },

      refreshAccessToken: async () => {
        const { refreshToken } = get();
        if (!refreshToken) return;

        try {
          const res = await refresh({ tokens: refreshToken });
          const data = res.data;

          if (data && data.access_token) {
            set({ accessToken: data.access_token });
            return data.access_token;
          } else {
            console.error('Refresh token failed: no access token received');
          }
        } catch (error) {
          console.error('Failed to refresh access token:', error);
          set({ accessToken: null, refreshToken: null });
        }
      },
    }),
    {
      name: 'auth-store',
    }
  )
);
