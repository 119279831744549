import axiosInstance from '@/utils/axiosInstance';
import { useAuthStore } from '@/stores/useAuthStore';

export const logout = async () => {
  const { accessToken, doLogout } = useAuthStore.getState();

  try {
    const response = await axiosInstance.post('/auth/logout', {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 201 ) {
      doLogout(); // Clear auth store
      return true;
    } else {
      console.error('Logout failed with status:', response.status);
      return false;
    }
  } catch (error) {
    console.error('Logout error:', error);
    return false;
  }
};
