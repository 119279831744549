import { lazy } from "react";
import { ProtectedRoute } from "@/ProtectedRoute";

// Lazy-loaded components
const DataPenilaian = lazy(() => import("@/pages/Dashboard/dataPenilaian"));
const Dashboard = lazy(() => import("@/pages/Dashboard/dashboard"));
const Penilaian = lazy(() => import("@/pages/Dashboard/dataPeserta"));
const DetailRound = lazy(() => import("@/pages/Dashboard/detailRound"));
const ManageData = lazy(() => import("@/pages/Dashboard/kelolaData"));

// Non-lazy-loaded components
import Monitor from "@/pages/Dashboard/monitor";
import { NotFound } from "@/pages/NotFound/NotFound";
import LoginForm from "@/pages/Auth/Login";

const routes = [
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/input-penilaian",
    element: (
      <ProtectedRoute>
        <DataPenilaian />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/data-peserta",
    element: (
      <ProtectedRoute>
        <Penilaian />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/round-detail",
    element: (
      <ProtectedRoute>
        <DetailRound />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/kelola-data",
    element: (
      <ProtectedRoute allowedEmails={["superadmin@example.com", "superuser@example.com"]}>
        <ManageData />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/monitor",
    element: (
      <ProtectedRoute>
        <Monitor />
      </ProtectedRoute>
    ),
  },
  { path: "/", element: <Monitor /> },
  { path: "/admin/login", element: <LoginForm /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
