// App.jsx
import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./index.css";
import Sidebar from "./navbar/Sidebar";
import routes from "./routes/routesConfig";
import LoadingFallback from "@/components/loading/loadingFallback";
import { ToastProvider, ToastViewport } from "./components/ui/toast";
import { Toaster } from "./components/ui/toaster";

function AppContent() {
  const location = useLocation();
  const isDashboardPage = location.pathname.startsWith("/dashboard");

  return (
    <div className="flex">
      {isDashboardPage && <Sidebar />}
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Suspense>
    </div>
  );
}

function App() {
  return (
    <ToastProvider>
      <AppContent />
      <Toaster />
    </ToastProvider>
  );
}

export default App;
