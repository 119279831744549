import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "@/services/auth/logout";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { HamburgerMenuIcon, DashboardIcon, Pencil2Icon, ClipboardCopyIcon, ListBulletIcon, BarChartIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { useAuthStore } from "@/stores/useAuthStore"; // Import useAuthStore

const SidebarLink = ({ to, icon: Icon, label, onClick }) => {
  const { pathname } = useLocation();
  const isActive = pathname === to;

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`flex items-center p-2 rounded-lg group ${
        isActive ? "bg-green-500 text-white" : "text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
      }`}
      style={{ backgroundColor: isActive ? "green" : "transparent" }}
    >
      <Icon className="mr-3 text-3xl" />
      {label}
    </Link>
  );
};

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { authenticatedEmail } = useAuthStore(); // Ambil email yang terautentikasi dari store

  const handleLinkClick = (path) => {
    setIsOpen(false);
    navigate(path);
  };

  const handleLogout = async () => {
    const success = await logout();
    if (success) {
      navigate("/admin/login");
    }
  };

  const canAccessKelolaData = () => {
    const allowedEmails = ["superadmin@example.com", "superuser@example.com"];
    return allowedEmails.includes(authenticatedEmail);
  };

  return (
    <>
      <div className="flex absolute sm:hidden">
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetTrigger asChild>
            <Button variant="ghost" className="fixed m-3 border-slate-900 border border-lg bg-slate-300 w-9 p-0">
              <HamburgerMenuIcon width={100} />
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <SheetHeader>
              <SheetTitle className="text-left mb-7">
                <div className="logo flex justify-start items-center gap-3">
                  <img src="/logo.png" width={50} alt="PAKUALAMAN" />
                  <h2 className="text-[green]">PAKUALAMAN</h2>
                </div>
              </SheetTitle>
              <ul className="flex flex-col gap-5">
                <li>
                  <SidebarLink to="/dashboard" icon={DashboardIcon} label="Dashboard" onClick={() => handleLinkClick('/dashboard')} />
                </li>
                <li>
                  <SidebarLink to="/dashboard/input-penilaian" icon={Pencil2Icon} label="Penilaian" onClick={() => handleLinkClick('/dashboard/input-penilaian')} />
                </li>
                <li>
                  <SidebarLink to="/dashboard/data-peserta" icon={ClipboardCopyIcon} label="Data Peserta" onClick={() => handleLinkClick('/dashboard/data-peserta')} />
                </li>
                <li>
                  <SidebarLink to="/dashboard/round-detail" icon={ListBulletIcon} label="Detail Rambahan" onClick={() => handleLinkClick('/dashboard/round-detail/some-id')} />
                </li>
                {canAccessKelolaData() && (
                  <li>
                    <SidebarLink to="/dashboard/kelola-data" icon={BarChartIcon} label="Kelola Data" onClick={() => handleLinkClick('/dashboard/kelola-data')} />
                  </li>
                )}
                <li>
                  <Button onClick={handleLogout} className="text-white py-2 px-2 rounded-lg mt-12 w-full flex justify-center">
                    Log out
                  </Button>
                </li>
              </ul>
            </SheetHeader>
          </SheetContent>
        </Sheet>
      </div>

      <aside
        id="default-sidebar"
        className="sticky hidden sm:flex h-screen top-0 left-0 z-40 mr-3 transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="sticky h-screen px-3 py-5 w-[240px] overflow-y-auto bg-slate-50 dark:bg-gray-800">
          <div className="logo flex justify-start items-center gap-3">
            <img src="/logo.png" width={50} alt="PAKUALAMAN Logo" />
            <h2 className="text-[green] font-bold">PAKUALAMAN</h2>
          </div>
          <ul className="sticky space-y-2 mt-[20px] font-medium">
            <li>
              <SidebarLink to="/dashboard" icon={DashboardIcon} label="Dashboard" onClick={() => handleLinkClick('/dashboard')} />
            </li>
            <li>
              <SidebarLink to="/dashboard/input-penilaian" icon={Pencil2Icon} label="Penilaian" onClick={() => handleLinkClick('/dashboard/input-penilaian')} />
            </li>
            <li>
              <SidebarLink to="/dashboard/data-peserta" icon={ClipboardCopyIcon} label="Data Peserta" onClick={() => handleLinkClick('/dashboard/data-peserta')} />
            </li>
            <li>
              <SidebarLink to="/dashboard/round-detail" icon={ListBulletIcon} label="Detail Rambahan" onClick={() => handleLinkClick('/dashboard/round-detail/some-id')} />
            </li>
            {canAccessKelolaData() && (
              <li>
                <SidebarLink to="/dashboard/kelola-data" icon={BarChartIcon} label="Kelola Data" onClick={() => handleLinkClick('/dashboard/kelola-data')} />
              </li>
            )}
            <li>
              <Button onClick={handleLogout} className="text-white py-2 px-0 rounded-lg mt-12 w-full flex justify-center">
                Log out
              </Button>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
